import React from 'react';
import { RouteFormatter } from '../routes';

const MyLink = ({ href = '', text = '' }) => (
    <a href={href} target="_blank" rel="noreferrer" className="snif-semibold text-primary text-underline">
        {text}
    </a>
);

export const LISTING_FAQS_CONTENT = [
    {
        que: 'Are public dog parks good for dogs?',
        ans: () => (
            <>
                <p className="mb-1">
                    It is important for most dogs to have{' '}
                    <MyLink
                        href="https://www.sniffspot.com/blog/dog-training/definitive-guide-to-off-leash-training-with-your-dog"
                        text="off-leash exercise"
                    />{' '}
                    and plenty of opportunities to explore. Engaging in natural behaviors like sniffing and running is valuable for your pet's mental
                    health! Since letting dogs off leash in city parks can be dangerous for the surrounding community and wildlife — not to mention
                    can lead to issues with animal control or park authority departments — public dog parks are a legal way to let dogs have off leash
                    exercise.
                </p>
                <p className="mb-1">
                    But the free and open nature of public dog parks can have drawbacks. While the popularity of dog parks has been skyrocketing in
                    the United States, so has the number of{' '}
                    <MyLink href="https://www.sniffspot.com/blog/dog-trainers/united-states" text="professional trainers" /> who caution against their
                    use. The top risks associated with public off-leash dog parks are that:
                </p>
                <ul className="mb-1">
                    <li>Dog parks can create an unnatural social environment</li>
                    <li>These might be aggressive dogs present — and other dog owners are also inattentive to their pets' behavior</li>
                    <li>Busy dog parks can overwhelm shy or nervous dogs</li>
                    <li>Dog parks can exacerbate problem behaviors like leash reactivity</li>
                    <li>Bad experiences at a dog park can also create behavior problems in the first place</li>
                    <li>Public dog parks can lead to physical injuries</li>
                    <li>
                        There might be disease transmission and cleanliness issues, since any given public dog park facility may not be cleaned
                        regularly
                    </li>
                </ul>
                <p>
                    Many dog behaviorists discourage visiting public dog parks and recommend{' '}
                    <MyLink href={RouteFormatter.listings({})} text="Sniffspot's private dog parks" />. With Sniffspot, dogs can get their exercise
                    safely — without worrying about other dogs, people, or potential disease transmission — because our private dog parks only allow
                    one guest to book at a time and are not open to the public. This solves the above issues!
                </p>
            </>
        ),
    },
    {
        que: 'How do public dog parks create an unnatural social environment for dogs?',
        ans: () => (
            <>
                <p className="mb-1">
                    Many owners are drawn to dog parks because they want to{' '}
                    <MyLink href="https://www.sniffspot.com/blog/dog-training/how-to-socialize-a-dog" text="socialize their pets" />. We're inundated
                    with messages of the “perfect” friendly dog. Our social media feeds feature viral videos of animal best friends that echo the
                    cutesy movies we loved as children — but they often show a false reality.
                </p>
                <p className="mb-1">
                    While dogs are social mammals, it's typically not natural for them to regularly engage in play with strangers.{' '}
                    <MyLink
                        href="https://www.akc.org/expert-advice/training/understanding-scale-dog-selectivity/"
                        text="Dog sociability can be viewed as a sliding scale"
                    />
                    :
                </p>
                <ul className="mb-1">
                    <li>
                        Some dogs are truly <span className="snif-semibold">dog social</span>. This means they genuinely enjoy interaction with almost
                        every dog they meet! We tend to think these dogs are more common than they really are — they're the ones we most see out and
                        about because they can handle the widest range of environments.
                    </li>
                    <li>
                        Most dogs are <span className="snif-semibold">dog tolerant</span> or <span className="snif-semibold">dog selective</span>.
                        They don't often seek out new friends, but they can comfortably interact with other dogs when needed after a proper
                        introduction process. (
                        <MyLink
                            href="https://www.sniffspot.com/blog/dog-enrichment/how-to-introduce-dogs"
                            text="You can read more about properly introducing dogs who don't know each other in this article"
                        />
                        .)
                    </li>
                    <li>
                        Some dogs are <span className="snif-semibold">dog </span>
                        <MyLink href="https://www.sniffspot.com/blog/dog-reactivity/guide-to-fear-aggression-in-dogs" text="aggressive" />. They might
                        live with or know a few specific canine friends, but they don't generally enjoy being around their own species.
                    </li>
                </ul>
                <p>
                    Ultimately: It's perfectly normal for our dogs to not want to play with other pets outside of our families.{' '}
                    <MyLink href={RouteFormatter.web.about()} text="Sniffspot was actually inspired by a dog-selective rescue" /> who wasn't able to
                    get any time off leash in a city environment, making it difficult to meet his basic needs amongst the hustle and bustle.
                </p>
            </>
        ),
    },
    {
        que: 'Can one bad experience at a public dog park have a lasting effect on a dog?',
        ans: () => (
            <>
                <p className="mb-1">
                    Even a previously social dog might develop a behavior issue (like{' '}
                    <MyLink href="https://www.sniffspot.com/blog/dog-reactivity/guide-to-fear-aggression-in-dogs" text="fear reactivity" />) due to a
                    negative interaction with another dog at a public dog park. Perhaps they get subtly bullied, feel overwhelmed, or are even bit —
                    and they decide to preemptively try to keep other dogs away from them. Now your social dog is selective or even aggressive toward
                    others.
                </p>
                <p>
                    While some dogs seem to let those negative interactions roll off their back (especially if they've been well socialized since
                    puppyhood) others are affected in lasting ways. Each dog and situation is different.
                </p>
            </>
        ),
    },
    {
        que: 'What are the risks of physical injuries and illnesses at public dog parks?',
        ans: () => (
            <>
                <p className="mb-1">
                    Public dog parks also increase the chances of pets{' '}
                    <MyLink
                        href="https://www.sniffspot.com/blog/sniffspot-community/the-state-of-public-dog-parks-across-the-united-states"
                        text="sustaining injury"
                    />{' '}
                    or developing infection.
                </p>
                <h4 className="snif-p snif-semibold mb-05">Dog park injuries</h4>
                <p className="mb-1">
                    While many dog-dog injuries at dog parks are accidental — canines can be mismatched in size, inadvertently break skin while
                    wrestling with untrimmed nails, or simply come on too strong without realizing — others are overtly aggressive.
                </p>
                <p className="mb-1">
                    Even friendly dogs can start fights by failing to recognize another pet's signals of discomfort.{' '}
                    <MyLink href="https://www.sniffspot.com/blog/dog-training/food-aggression-how-to-handle-it" text="Resource guarding over food" />{' '}
                    and toys can turn into a dangerous scuffle. Sometimes heightened arousal from being in a large social group elevates even a
                    typically clear-headed dog's prey drive.
                </p>
                <h4 className="snif-p snif-semibold mb-05">Dog park illnesses</h4>
                <p className="mb-1">
                    No matter how hard a city works to keep their parks clean, it's impossible to fully eliminate all infection risk. This is
                    especially true in dog-designated spaces that see dozens, or perhaps hundreds, of different canines each week.
                </p>
                <p className="mb-1">
                    There's no way to confirm that every dog entering an off-leash space has been fully vaccinated — and bacterial diseases like
                    leptospirosis often thrive in wet, muddy terrain that's been torn up by paws.
                </p>
                <p>Young puppies and elderly dogs have the greatest chance of getting sick.</p>
            </>
        ),
    },
    {
        que: 'Are all public dog parks dangerous?',
        ans: () => (
            <>
                <p className="mb-1">
                    While dog parks certainly come with their risks, it would be unfair to claim they're always a bad idea. Public dog parks do serve
                    an important community service in cities.
                </p>
                <p>
                    Some areas lend themselves to safe interactions better than others — large plots of land with acres to maneuver are less dangerous
                    than fenced-in city runs, for example — and responsible owner involvement can make a world of difference.
                </p>
            </>
        ),
    },
    {
        que: 'How do I know if a dog park works for my dog?',
        ans: () => (
            <>
                <p className="mb-1">
                    Dogs enjoy Sniffspot dog parks for all kinds of different reasons — spanning from physical exercise to behavior modification
                    training to agility or other dog sports practice and more. Whatever your primary goals, there is a Sniffspot dog park for you!
                </p>
                <p className="mb-1">
                    There are many different types of private dog parks available. Sniffspot hosts offer locations like designated training yards;
                    expansive fields; large, fully-fenced off-leash dog parks; indoor facilities with lights and other amenities; dog sports courses
                    complete with agility equipment; pools and natural water features; and more. Some even have niche amenities, such as a park splash
                    pad and dog-friendly potable water fountains.
                </p>
                <p>
                    You can filter Sniffspot dog parks by size, fencing, and distractions that might be present via our dog park locator. If your dog
                    doesn't have a good recall, you can visit one of our fully fenced dog parks. If your dog is reactive to other dogs, you can visit
                    one of our off-leash areas where there are no dogs audible or visible nearby. You can also filter locations to avoid other
                    domestic animals and people! Each listing includes the park hours of operation — and by reading reviews you can understand the
                    status of condition, for example, if a section of the fence is in poor condition or there are any park maintenance issues.
                </p>
            </>
        ),
    },
    {
        que: 'Are Sniffspot dog parks safe?',
        ans: () => (
            <>
                <p className="mb-1">
                    While public dog parks have a number of safety issues, such as aggressive dogs, disease transmission, and general cleanliness
                    (often as much an issue with the person in charge as with their dog) Sniffspot dog parks are much safer environments to let your
                    dog express their natural behaviors.
                </p>
                <p className="mb-1">
                    With Sniffspot, all bookings are private — the only park users at a given time are the dog owners who signed up and dogs they
                    bring with them in their own group. Additionally, we require all dogs to be vaccinated (or have equivalent titers). Hosts are in
                    charge of keeping their park areas clean, and many go out of their way to provide visitors with exceptional experiences. It's a
                    win-win for everyone in the community!
                </p>
                <p className="mb-1">
                    Perhaps most helpful to know exactly what you're getting into: Sniffspot dog park locations are vetted and reviewed by guests, so
                    you can find the perfect place for you and your dog — whether they have behavioral struggles, special needs, or any other specific
                    considerations. 93% of reviews on Sniffspot visits are 5 stars and many spots have been reviewed hundreds of times.
                </p>
                <p>As always, make sure to check weather conditions before visiting.</p>
            </>
        ),
    },
    {
        que: 'Is a Sniffspot dog park an off leash area?',
        ans: () => (
            <>
                <p className="mb-1">
                    Yes. Many Sniffspot dog parks are designed to be a designated off-leash area! That said: Not every Sniffspot private dog park
                    location is completely fenced in.
                </p>
                <p>
                    All dogs are different, and we expect our guests to be safe and responsible — especially if their dogs do not have strong recall
                    to be able to remain under voice control. Off leash dogs should always stay within the designated off-leash dog park property.
                    This is important to have an enjoyable time and be respectful to the hosts, neighbors, and community as a whole!
                </p>
            </>
        ),
    },
    {
        que: 'Will there be other dogs at the Sniffspot dog park at the same time as me?',
        ans: () => (
            <>
                <p className="mb-1">
                    No, there will not. Sniffspot only allows one booking at a time — and any host dogs are required to be kept away from the spot
                    during visits.
                </p>
                <p>
                    We also enforce buffers between bookings to ensure you aren't arriving or leaving at the same time another park user is present.
                </p>
            </>
        ),
    },
    {
        que: 'How can I find Sniffspot dog parks near me?',
        ans: () => (
            <>
                <p>
                    You can <MyLink href={RouteFormatter.listings({})} text="browse all of our Sniffspots here using our dog park locator" />. You can
                    easily view information about the spots, including open times, park entrance details, and current conditions.
                </p>
            </>
        ),
    },
    {
        que: 'How much do Sniffspot dog parks cost?',
        ans: () => (
            <>
                <p className="mb-1">
                    Each host sets their own price, so you can check the cost per hour on the specific Sniffspot dog park listing. Most are between $5
                    - $15 per dog per hour.
                </p>
                <p>
                    Prices vary based on size, fencing and amenities, such as water or drinking fountains for dogs, park benches, WiFi accessibility,
                    and so on.
                </p>
            </>
        ),
    },
    {
        que: 'What are the requirements to bring my dog to a Sniffspot dog park?',
        ans: () => (
            <>
                <h4 className="snif-p snif-semibold mb-05">Vaccinations are required</h4>
                <p className="mb-1">
                    We require that all dogs have basic vaccinations or equivalent titers. We require rabies vaccination, the equivalent of current
                    rabies tags.
                </p>
                <h4 className="snif-p snif-semibold mb-05">Dogs should not display aggressive behavior</h4>
                <p className="mb-1">We do not allow dogs with a bite history.</p>
                <h4 className="snif-p snif-semibold mb-05">There are sometimes limits on number of dogs per booking</h4>
                <p className="mb-1">
                    We do not have limits for dogs per owner or dogs per adult handler, though some individual park hosts set person per visit limits.
                </p>
                <h4 className="snif-p snif-semibold mb-05">Supervision is always required</h4>
                <p className="mb-1">Adult supervision is required at all times.</p>
                <h4 className="snif-p snif-semibold mb-05">Clean up after your pets</h4>
                <p className="mb-1">
                    Dog owners are responsible for picking up pet waste to keep park areas clean (many Sniffspot private dog parks provide pet waste
                    stations).
                </p>
                <h4 className="snif-p snif-semibold mb-05">You must sign a waiver and agree to our policies</h4>
                <p>We require that guests sign our waiver and agree to follow park rules and policies before visiting park sites.</p>
            </>
        ),
    },
    {
        que: 'How can I offer my land as a private dog park?',
        ans: () => (
            <>
                <p>
                    <MyLink
                        href={RouteFormatter.hostLanding()}
                        text="You can learn more about how you can earn up to $3,000 per month sharing your land here"
                    />
                    . It is just as lucrative as dog boarding or other dog services — with less work for you! Park planning can be as simple as
                    opening up your existing yard.
                </p>
            </>
        ),
    },
    {
        que: 'Why would I want to offer my land as a private dog park?',
        ans: () => (
            <>
                <p className="mb-1">Being a Sniffspot host can be incredibly rewarding both financially and personally.</p>
                <p>
                    We love dogs — but our modern world is built for us humans, not for our canine companions. Tightly packed cities, small public dog
                    parks, and less time in nature have increased the rates of problem behaviors like{' '}
                    <MyLink href="https://www.sniffspot.com/blog/dog-reactivity" text="leash reactivity" />, fear aggression, and{' '}
                    <MyLink
                        href="https://www.sniffspot.com/blog/dog-reactivity/dog-anxiety-signs-and-how-to-help-your-pup-feel-better"
                        text="anxiety"
                    />{' '}
                    in our pets. Sniffspot is a way to combat this mental and physical health crisis! By providing a safe environment, you can enable
                    local dogs and owners to breathe a collective sigh of relief as they get to{' '}
                    <MyLink
                        href="https://www.sniffspot.com/blog/dog-enrichment/comprehensive-guide-to-enrichment-activities-for-dogs"
                        text="engage in natural behaviors"
                    />{' '}
                    (like sniffing, running, and playing) in a private space free of potential dangers they can't escape at city parks or on the
                    sidewalk.
                </p>
            </>
        ),
    },
];
